/* eslint-disable no-undef */

import CookieConsent from '../../components/cookie-consent/cookie-consent';

// Cookie consent
(async () => {
  const cookieOptions = await import(`../../components/cookie-consent/cookie-options-${document.documentElement.lang}.json`);
  // eslint-disable-next-line no-unused-vars
  const consent = new CookieConsent(cookieOptions);
})();

jQuery(document).ready(($) => {
  // Preloader
  // ------------------------------------------------------
  $(window).on('load', () => {
    anime.timeline({
      easing: 'easeOutExpo',
    });
  });

  // Contact Form Validate JS
  // ------------------------------------------------------

  if ($('.pr__contact').length) {
    $('.pr__contact').validate({
      // Initialize the plugin
      rules: {
        name: {
          required: true,
        },
        email: {
          required: true,
          email: true,
        },
        subject: {
          required: true,
        },
        message: {
          required: true,
        },
      },
      submitHandler(form) {
        const formData = new FormData(form);

        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: new URLSearchParams(formData).toString(),
        })
          .then(() => {
            window.location.href = '/success';
          })
          .catch();
      },
    });
  }
});
